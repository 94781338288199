import React, { useState } from "react";
import "../styles/global.css";
import axios from "axios";

const API_PATH = `${process.env.GATSBY_EMAIL_API_PATH}`;

const Feedback = () => {

      const [mailSent, setMailSent ] = useState('');
      const [state, setState] = useState({
        "submitterEmail": "",
        "submitterFirstName": "",
        "submitterLastName": "",
        "submitterMessage": "",
        "fields": {},
        "errors": {}
      });
      const textAreaCharacterCount = 500;

    const handleFormSubmit = () => {
      if (handleValidation()) {
        axios({
          method: 'POST',
          url: `${API_PATH}`,
          headers: { 'content-type': 'application/json' },
          data: {
            submitterEmail: state.email,
            submitterFirstName: state.firstName.trim(),
            submitterLastName: state.lastName.trim(),
            submitterMessage: state.message
          }
        })
        .then(async (result) => {
          if (result.status === 200) {
            setState({...state, body: "", email: ""});
            setMailSent(true);
          } else {
            setMailSent(false);
          };
          setState({...state, isLoading: false});
        })
        .catch( async (error) => {
          setMailSent(false);
          setState({...state, isLoading: false});
        })
        setState({...state, isLoading: true}); 
      };
    };  

    const handleValidation = () => {
      let fields = {...state.fields};
      let errors = {};
      let formIsValid = true;

      //First Name
      if(!fields["firstName"]){
        formIsValid = false;
        errors["firstName"] = "Please enter a First Name";
      } else if(fields["firstName"].trim() === "") {
        formIsValid = false;
        errors["firstName"] = "Please enter a First Name";
      }

      if(typeof fields["firstName"] !== "undefined"){
        if(!fields["firstName"].match(/^[a-zA-Z\s.\-,]*$/)){          
          formIsValid = false;
          errors["firstName"] = "Please enter a Valid Value";
        }        
      }

      //Last Name 
      if(!fields["lastName"]){
        formIsValid = false;
        errors["lastName"] = "Please enter a Last Name";
      } else if(fields["lastName"].trim() === "") {
        formIsValid = false;
        errors["lastName"] = "Please enter a Last Name";
      }

      if(typeof fields["lastName"] !== "undefined"){
        if(!fields["lastName"].match(/^[a-zA-Z\s.\-,]*$/)){
          formIsValid = false;
          errors["lastName"] = "Please enter a Valid Value";
        }        
      }
 
      //Email
      if(!fields["email"] || fields["email"].trim() === "" ){
         formIsValid = false;
         errors["email"] = "Please enter a valid E-Mail";
      } else if(typeof fields["email"] !== "undefined") {
         let lastAtPos = fields["email"].lastIndexOf('@');
         let lastDotPos = fields["email"].lastIndexOf('.');

         if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
            formIsValid = false;
            errors["email"] = "E-mail is not valid";
          }
       }
      
      //Message
      if(!fields["message"] || fields["message"].trim() === ""){
        formIsValid = false;
        errors["message"] = "Please enter a message";
      }

      if (!formIsValid) {
        setState({...state, errors: errors});
      }

      return formIsValid;
    }

    const handleChange = async (event) => {
      state.fields[event.target.name] = event.target.value;
      await setState({...state, [event.target.name]: event.target.value});
    }

    return (
      <div className="row">
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2"></div>
        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10">
          { mailSent !== true && 
            <div>
              <div className="col-xl-5 col-lg-8 col-md-8 col-sm-10" style={{paddingLeft: 0}}>
                <div><span className="hear-From-You">We’d love to hear from you!</span></div><br></br>
                <div><span className="contact-us">Please contact us for any questions, feedback, or support for NEO mobile app.</span></div>
                <br></br>
              </div>
              <div>
                <div name="feedbackForm">
                  <div className="row" style={{marginLeft: 0}}>
                    <div className="col-xl-3.5 col-lg-3.5 col-md-3.5 col-sm-3.5">
                        <label htmlFor="firstName" className="name-label">Name *</label><br></br>
                        <input 
                          className="name-field" 
                          type="text" 
                          name="firstName" 
                          id="firstName" 
                          placeholder="First"
                          minLength = "1"
                          maxLength="60" 
                          value={state.firstName} 
                          onChange={handleChange}
                          required 
                        /><br></br>
                        <span className="name-error" style={{color: "red"}}>{state.errors["firstName"]}</span>
                    </div>
                    <div className="col-xl-3.5 col-lg-3.5 col-md-3.5 col-sm-3.5" style={{paddingLeft: 0}}>
                      <label htmlFor="lastName" className="last-name-label">Name</label><br></br>
                      <input 
                        className="name-field" 
                        type="text" 
                        name="lastName" 
                        id="lastName" 
                        placeholder="Last" 
                        minLength = "1"
                        maxLength="60"
                        value={state.lastName} 
                        onChange={e => {
                          setState({...state, senderLastName: e.target.value });
                          handleChange(e);
                        }}
                        required 
                      /><br></br>
                      <span className="name-error" style={{color: "red"}}>{state.errors["lastName"]}</span><br></br>
                    </div>
                  </div>
                  <br></br>
                  <label htmlFor="email" className="name-label">E-Mail *</label><br></br>
                  <input 
                    className="name-field" 
                    type="email" 
                    name="email" 
                    id="email" 
                    placeholder="E-Mail" 
                    value={state.email} 
                    onChange={e => {
                      setState({ ...state, senderEmail: e.target.value });
                      handleChange(e);
                    }}
                    required 
                  /><br></br>
                  <span style={{color: "red"}}>{state.errors["email"]}</span>
                  <br></br>
                  <br></br>
                  <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7" style={{paddingLeft: 0}}>
                    <label htmlFor="message" className="help-label">How can we help you? *</label><br></br>
                    <textarea
                      id="message" 
                      className="text-area" 
                      name="message" 
                      maxLength="500"
                      rows="50" 
                      cols="35" 
                      value={state.message}
                      onChange={e => {
                        setState({...state, body: e.target.value });
                        handleChange(e);
                      }}
                      required>
                    </textarea>
                    <span style={{color: "red"}}>{state.errors["message"]}</span><br></br>
                    <p class="textarea-character-limit">{state.message? state.message.length : 0} / {textAreaCharacterCount}</p>
                  </div>
                  <div>   
                    <br></br> 
                    <br></br>                
                    <button 
                      className="send-info" 
                      id="send-info"
                      onClick={() => handleFormSubmit()}
                    >
                      Submit
                    </button>
                    {state.isLoading === true &&                
                      <div className="loading"></div>
                    }
                  </div>  
                  {mailSent === false &&                
                    <div className="error-message">
                      <br></br>
                      There was an error trying to send this request.
                    </div>
                  }            
                </div>
              </div>
            </div> 
          }
          <div>
            {mailSent === true &&                
              <div className="success-message">
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                Thank you for contacting us!
              </div>
            }
          </div> 
          <br></br>       
          <br></br>
          <br></br>
        </div>
      </div>
    );
  }

  export default Feedback;

import React from "react"
import "../styles/global.css"
import DownloadApp from "../components/downloadapp"

export default function MainContent() {
  return (
    <div>
      <div className="top-logo"></div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br> 
      <br></br>
      <br></br>
      <div className="row">
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2"></div>
        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10">
          <span className="neo">NEO</span>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2"></div>
        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10">
          <p className="byOnline">by Online Business Systems</p>
          <br></br>
        </div> 
      </div>
      <div className="row">
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2"></div>
        <div className="col-xl-4 col-lg-8 col-md-8 col-sm-8">
          <span className="news">News and Everything Online - Our internal community app aims to keep Onliners connected and informed
        with the most vital company and client information.</span>
          <br></br>          
          <br></br>
          <a href="privacy">Privacy Policy</a>
          <br></br>
          <br></br>
        </div>
        <br></br>
      </div>
      <DownloadApp />
      <div className="row">
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2"></div>
        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10">
          <p className="online-info">Online Business Systems<br></br>
          200-115 Bannatyne Avenue <br></br>
          Winnipeg, Manitoba <br></br>
          R3B 0R3 Canada <br></br><br></br>
          Phone: 204.982.0200 <br></br> 
          Toll-free: 800.668.7722 <br></br></p>
        </div>
      </div>
    </div>
  )
}